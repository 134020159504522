'use client';
import { TrackingCallback, TrackingData } from '@growthbook/growthbook';
import { useEffect } from 'react';

import { sendAmplitudeLog } from 'components/app/analytics/amplitude/Amplitude.utils';
import { AmplitudeEvent } from 'components/app/analytics/amplitude/Amplitude.types';

export const onExperimentView: TrackingCallback = (experiment, result) => {
  // eslint-disable-next-line no-console
  console.log('Viewed Experiment', {
    experimentId: experiment.key,
    variationId: result.key,
  });
  sendAmplitudeLog(AmplitudeEvent.EXPERIMENT_VIEWED, {
    experimentId: experiment.key,
    variationId: result.key,
  });
};

// Helper component to track experiment views from server components
export function GrowthBookTracking({ data }: { data: TrackingData[] }) {
  useEffect(() => {
    data.forEach(({ experiment, result }) => {
      onExperimentView(experiment, result);
    });
  }, [data]);

  return null;
}
