import classNames from 'classnames';

import { SidebarFilter } from 'components/elements/organisms/algoliaConnected/SidebarFilter/SidebarFilter';
import { useFormatMessage } from 'utilities/i18n';
import { Divider } from 'components/elements/atoms/divider/Divider';
import { SearchInput } from 'components/pages/searchPage/components/SearchInput/SearchInput';
import { Tooltip } from 'components/elements/molecules/tooltip/Tooltip';
import { Icon } from 'components/elements/atoms/icon/Icon';
import { Checkbox } from 'components/elements/atoms/checkbox/Checkbox';
import { ClassIsFreeMenu } from 'components/pages/searchPage/components/ClassIsFreeMenu/ClassIsFreeMenu';

export enum ClassSpecificSearchableFilters {
  ISFREE = 'isFree',
  LEVEL = 'extraSettings.level',
  SOURCE = 'extraSettings.source',
  CATEGORY = 'category',
}

export function ClassSearchSidebar() {
  const t = useFormatMessage();

  return (
    <div className="bg-white">
      <h3 className="p-5 text-sm font-bold">{t('component.sidebar.search_and_filter')}</h3>
      <Divider thick />
      <div className="flex flex-col gap-5 p-5">
        <SearchInput />
        <Divider className="relative left-[-20px] w-[calc(100%+40px)]" />
        <ClassIsFreeMenu />
        <Divider className="relative left-[-20px] w-[calc(100%+40px)]" />
        <SidebarFilter attribute={`${ClassSpecificSearchableFilters.LEVEL}`} header={t('search.class_level')} />
        <SidebarFilter
          attribute={`${ClassSpecificSearchableFilters.SOURCE}`}
          header={t('search.class_creative_fabrica')}
        >
          {({ items, handleRefine }) => (
            <div className="mt-5 flex flex-col">
              {items.map((item, index) => {
                const cfOriginal = item.value === 'Original';

                return (
                  <div key={item.label + index} className="wrap flex items-center">
                    <Checkbox
                      label={
                        <span className="flex items-center gap-[3px]">
                          <span className={classNames(item.isRefined && 'font-semibold', 'capitalize')}>
                            {cfOriginal ? t('search.class_cf_original') : t('search.class_cf_sourced')}
                          </span>
                          <Tooltip
                            variant="primary"
                            placement="top"
                            title={
                              cfOriginal ? t('search.class_tooltip_cf_original') : t('search.class_tooltip_cf_sourced')
                            }
                          >
                            <Icon icon="question-circle" color="grayPurple.light" />
                          </Tooltip>
                          <span className="text-xs text-gray-500">({item.count})</span>
                        </span>
                      }
                      checked={item.isRefined}
                      onClick={() => handleRefine(item.value)}
                      className="!mr-0"
                    />
                  </div>
                );
              })}
            </div>
          )}
        </SidebarFilter>
        <SidebarFilter
          attribute={`${ClassSpecificSearchableFilters.CATEGORY}`}
          header={t('search.filter.categories')}
          hideEndDivider={true}
        />
      </div>
    </div>
  );
}
