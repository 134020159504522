import { useSortBy, useInfiniteHits, useSearchBox, useStats, useInstantSearch } from 'react-instantsearch';
import { useRef, Fragment } from 'react';
import algoliaAnalytics from 'search-insights';
import { Hit } from 'instantsearch.js/es/types';
import { useCookies } from 'react-cookie';

import { Select } from 'components/elements/atoms/select/Select';
import { useFormatMessage } from 'utilities/i18n';
import { useInifiteScrollTrigger } from 'hooks/useInfiniteScrollTrigger/useInfiniteScrollTrigger';
import {
  productsAlgoliaIndex,
  productsAlgoliaIndexNewest,
  productsAlgoliaIndexOldest,
} from 'components/contexts/instantSearchProvider/InstantSearchProvider.utils';
import { useLocalStorage } from 'hooks/useLocalStorage/useLocalStorage';
import { useOptionalUser } from 'hooks/useAuthState/useAuthState';
import { AlgoliaEventName } from 'components/app/analytics/algolia/Algolia.types';
import {
  AlgoliaContentHit,
  AlgoliaSearchedProductsLocalStorage,
} from 'components/elements/organisms/algoliaConnected/SearchResults/SearchResults.types';
import { SearchProduct } from 'api/requests/search/search.types';
import { Masonry, useCanShowMasonry } from 'components/elements/atoms/masonry/Masonry';
import { SearchResultProduct } from 'components/pages/searchPage/components/SearchResultProduct/SearchResultProduct';
import { useLocale } from 'hooks/useLocale/useLocale';
import { useActiveSubscriptionTypes } from 'hooks/useHasSubscription/useHasSubscription';
import { useSiteDataFetcher } from 'api/requests/site/site';
import { SparkBanner } from 'components/pages/searchPage/components/SparkBanner/SparkBanner';
import { FreeTrialCard } from 'components/pages/searchPage/components/FreeTrialCard/FreeTrialCard';
import { FutureImage } from 'components/elements/atoms/image/FutureImage';
import { getPathnameWithLocalePrefix } from 'components/app/App.utils';
import { AppRoute } from 'components/app/App.types';
import { SparkIgniteButton } from 'components/pages/sparkPage/components/sparkIgniteButton/SparkIgniteButton';
import { useAlgoliaContentFetch } from 'components/contexts/instantSearchProvider/SearchPageProvider';
import { SEARCH_PAGE_AI_CONTENT_ENABLED_TOGGLE_COOKIE_NAME } from 'components/pages/searchPage/components/SearchSidebar/SearchSidebar';
import { Spinner } from 'components/elements/atoms/spinner/Spinner';

export const ALGOLIA_SEARCHED_PRODUCTS_LOCAL_STORAGE_KEY = 'algoliaSearchedProducts';
const sevenDays = 7 * 24 * 60 * 60 * 1000;

export function SearchResults({
  showFreeTrialRef,
  searchForClasses,
  contentFilteredBy,
}: {
  showFreeTrialRef?: React.MutableRefObject<HTMLDivElement | undefined>;
  searchForClasses?: boolean;
  contentFilteredBy: string | undefined;
}) {
  const t = useFormatMessage();
  const { data: siteData } = useSiteDataFetcher();
  const { promotion } = siteData?.promo || {};
  const { locale, algoliaLang } = useLocale();
  const { hits: productHits, showMore, isLastPage, isFirstPage, showPrevious } = useInfiniteHits<SearchProduct>({});
  const { status, uiState } = useInstantSearch();
  const currentPage = uiState[productsAlgoliaIndex].page || 1;
  const refined = uiState[productsAlgoliaIndex].refinementList || {};
  const [cookies] = useCookies([SEARCH_PAGE_AI_CONTENT_ENABLED_TOGGLE_COOKIE_NAME]);

  const isAIContentEnabled = cookies[SEARCH_PAGE_AI_CONTENT_ENABLED_TOGGLE_COOKIE_NAME] === 'true';

  const shouldFetchContentData =
    isAIContentEnabled && !searchForClasses && Object.keys(refined).find(key => refined[key].length > 0) === undefined;

  const { query } = useSearchBox();
  const loadMoreRef = useRef<HTMLDivElement>();
  const showMasonry = useCanShowMasonry();
  const activeSubscriptionTypes = useActiveSubscriptionTypes();
  const SORTING_OPTIONS = [
    { value: productsAlgoliaIndex, label: t('search.sort_most_relevant'), type: 'popular' },
    { value: productsAlgoliaIndexNewest, label: t('search.sort_newest_first'), type: 'newest' },
    { value: productsAlgoliaIndexOldest, label: t('search.sort_oldest_first'), type: 'oldest' },
  ];

  const { nbHits, processingTimeMS } = useStats();
  const { refine, currentRefinement } = useSortBy({
    items: SORTING_OPTIONS,
  });

  const handleSort = (value: string) => {
    refine(value);
  };

  const {
    data: contentData,
    hasNextPage: contentHasNextPage,
    status: contentStatus,
    fetchNextPage: contentFetchNextPage,
    hasPreviousPage: contentHasPreviousPage,
    fetchPreviousPage: contentFetchPreviousPage,
  } = useAlgoliaContentFetch({
    page: currentPage - 1,
    query: query,
    enabled: shouldFetchContentData,
    filterBy: contentFilteredBy,
    sortBy: currentRefinement,
  });

  const shouldHideProductContent = isAIContentEnabled && contentFilteredBy !== undefined;

  const contentNbHits = contentData && 'nbHits' in contentData.pages[0] ? contentData.pages[0].nbHits || 0 : 0;

  const contentHits = contentStatus === 'success' ? contentData.pages.map(page => page.hits).flat() : [];

  let hits: Hit<SearchProduct | AlgoliaContentHit>[] = [];

  //We will show a ratio of 10 products to 1 content product
  if (contentHits.length > 0) {
    if (shouldHideProductContent || productHits.length === 0) {
      hits = contentHits as Hit<AlgoliaContentHit>[];
    } else {
      let ci = 0;

      //Iterate over the product hits and add the content hits every 10 products
      for (let i = 0; i < productHits.length; i++) {
        if (i > 0 && i % 10 === 0) {
          if (ci < contentHits.length) {
            hits.push(contentHits[ci] as Hit<AlgoliaContentHit>);
            ci++;
          }
        }

        hits.push(productHits[i]);
      }

      //If it is the last page of product hits, we need to add the rest of the content hits
      if (isLastPage) {
        hits.push(...(contentHits.slice(ci) as Hit<AlgoliaContentHit>[]));
      }

      const startPosition = hits[0].__position;

      hits.forEach((hit, index) => {
        hit.__position = startPosition + index;
      });
    }
  } else {
    hits = shouldHideProductContent ? [] : productHits;
  }

  const user = useOptionalUser();
  const [algoliaSearchedProducts, setAlgoliaSearchedProducts] = useLocalStorage<AlgoliaSearchedProductsLocalStorage>([
    'algoliaSearchedProducts',
    user?.identification.id || 'guest',
  ]);

  const handleNextPage = () => {
    if (!isLastPage && !shouldHideProductContent) {
      showMore();
    }

    if (contentHasNextPage) {
      contentFetchNextPage();
    }
  };

  const handlePreviousPage = () => {
    if (!isFirstPage && !shouldHideProductContent) {
      showPrevious();
    }

    if (contentHasPreviousPage) {
      contentFetchPreviousPage();
    }
  };

  const shouldFetchNextPage = () => {
    //if masonry not ready or alogia requests for products is ongoing, we should not fetch next page
    if (!showMasonry || status !== 'idle') {
      return false;
    }

    //if content data should now be fetched, we should check for products last page only
    if (!shouldFetchContentData) {
      return !isLastPage;
    }

    // if content data should be fetched, we should check if content data is fetching
    if (['success', 'idle'].indexOf(contentStatus) === -1) {
      return false;
    }

    //if we should not show product content,we should check for content last page only
    if (shouldHideProductContent) {
      return contentHasNextPage;
    }

    //if we are on both last page of products and content, we should not fetch next page
    if (!contentHasNextPage && isLastPage) {
      return false;
    }

    return true;
  };

  const noItemsFound =
    hits.length === 0 &&
    status === 'idle' &&
    ((shouldFetchContentData && contentStatus === 'success') || !shouldFetchContentData);
  const canFetchNextPage = shouldFetchNextPage();

  useInifiteScrollTrigger({
    fetchNextPage: handleNextPage,
    hasNextPage: canFetchNextPage,
    ref: loadMoreRef,
  });

  const handleProductClick = (prod: SearchProduct | AlgoliaContentHit) => {
    const prodId = String(prod.objectID);

    if (prodId) {
      const today = new Date();
      const futureDate = new Date(today.getTime() + sevenDays);

      if (
        !algoliaSearchedProducts ||
        new Date(algoliaSearchedProducts.expiresAt).getTime() < today.getTime() - sevenDays
      ) {
        const newAlgoliaSearchedProducts = {
          items: { [currentRefinement]: { [prodId]: prod.__queryID || '' } },
          expiresAt: futureDate,
        };
        setAlgoliaSearchedProducts(newAlgoliaSearchedProducts);
      } else {
        const newAlgoliaSearchedProducts = { ...algoliaSearchedProducts };

        if (!newAlgoliaSearchedProducts.items[currentRefinement]) {
          newAlgoliaSearchedProducts.items[currentRefinement] = { [prodId]: prod.__queryID || '' };
        } else {
          newAlgoliaSearchedProducts.items[currentRefinement] = {
            ...newAlgoliaSearchedProducts.items[currentRefinement],
            [prodId]: prod.__queryID || '',
          };
        }

        newAlgoliaSearchedProducts.expiresAt = futureDate;
        setAlgoliaSearchedProducts(newAlgoliaSearchedProducts);
      }

      algoliaAnalytics('clickedObjectIDsAfterSearch', {
        index: productsAlgoliaIndex,
        eventName: AlgoliaEventName.PRODUCT_CLICKED,
        queryID: prod.__queryID || '',
        objectIDs: [prodId],
        positions: [prod.__position || 0],
      });
    }
  };

  const hasAllAccess = user?.settings?.hasAllAccess || false;

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full flex-col gap-5">
        <div className="flex w-full items-center justify-between">
          <span>
            {shouldHideProductContent ? contentNbHits : nbHits + contentNbHits} {t('search.stats')} {processingTimeMS}ms
          </span>
          <Select items={SORTING_OPTIONS} value={currentRefinement} onChange={handleSort} variant="outlined" />
        </div>
        {!searchForClasses ? <SparkBanner query={query} /> : null}
        {!isFirstPage ? (
          <div className="flex w-full justify-center">
            <button
              className="w-fit rounded bg-primary-100 px-5 py-[10px] text-sm font-bold uppercase text-primary-500"
              onClick={handlePreviousPage}
            >
              {t('search.show_previous')}
            </button>
          </div>
        ) : null}
        <div className="mt-5">
          {noItemsFound ? (
            <div className="flex min-h-[300px] flex-col items-center gap-[10px]">
              <span>
                {t('search.no_results', {
                  values: {
                    query: query,
                    b: (...children: unknown[]) => <strong>{children}</strong>,
                  },
                })}
              </span>
              <span className="">
                <span className="inline">{t('search.no_results.generate')}</span>
                <FutureImage
                  src={`${process.env.NEXT_PUBLIC_IMAGE_URL}/cf-spark-logo.698041e5.svg`}
                  alt="Spark Logo"
                  width={128}
                  height={32}
                  className="ml-2 !inline"
                  imageClassName="inline-block"
                />
              </span>
              <SparkIgniteButton
                href={getPathnameWithLocalePrefix(
                  `${AppRoute.SPARK_ART_GENERATOR}${query.length > 0 ? `?pt=${query}` : ''}`,
                  locale,
                )}
                sx={{ width: { xs: '100%', md: 'fit-content' }, minWidth: '210px' }}
                state="idle"
              >
                {t('search.no_results.cta')}
              </SparkIgniteButton>
            </div>
          ) : null}
          {hits.length === 0 && (status === 'loading' || (shouldFetchContentData && contentStatus === 'loading')) ? (
            <div className="flex w-full justify-center">
              <Spinner size="2x" />
            </div>
          ) : null}
          {showMasonry && hits.length > 0 && (
            <Masonry spacing={10} columns={{ md: 2, lg: 3, xl: 4, default: 1 }}>
              {hits.map((product, index) => {
                //For some reason on live we have some products without url
                if ('url' in product === false && 'slug' in product === false) {
                  return null;
                }

                const showFreeTrialCard =
                  !hasAllAccess && (index === 0 || index === 16 || (index > 20 && index % 16 === 0));

                if ('slug' in product) {
                  return (
                    <Fragment key={`search_result_${product.objectID}_${index}`}>
                      {index === 1 ? <div ref={showFreeTrialRef as React.LegacyRef<HTMLDivElement>} /> : null}
                      <SearchResultProduct
                        productUrl={`/prod/${product.slug[algoliaLang]}-${product.objectID}?query_id=${product.__queryID}`}
                        image={`${process.env.NEXT_PUBLIC_CONTENT_IMAGES_ROOT_URL}/${product.thumbnail.url}`}
                        name={product.title[algoliaLang]}
                        category={product.authorId}
                        userHasAccess={hasAllAccess}
                        price={0}
                        discountedPrice={0}
                        promoActive={false}
                        discount={promotion?.discount}
                        onClick={() => handleProductClick(product)}
                      />
                      {showFreeTrialCard ? (
                        <div className="mt-[10px] w-full">
                          <FreeTrialCard />
                        </div>
                      ) : null}
                    </Fragment>
                  );
                }

                const productUrl = product.url.split('/product/')[1];
                const name = product[`name_${locale}`] || product.name_en;

                const price = product.price;
                let regularPrice = price;

                if (
                  product.regularPrice !== undefined &&
                  product.regularPrice !== false &&
                  product.regularPrice !== true &&
                  !isNaN(Number(product.regularPrice))
                ) {
                  regularPrice = product.regularPrice;
                }
                const countDown =
                  product.endDate !== undefined && Boolean(product.endDate) ? product.endDate * 1000 : undefined;

                return (
                  <Fragment key={`search_result_${product.objectID}_${index}`}>
                    {index === 1 ? <div ref={showFreeTrialRef as React.LegacyRef<HTMLDivElement>} /> : null}
                    <SearchResultProduct
                      productUrl={`/product/${productUrl}?index=${
                        SORTING_OPTIONS.find(item => item.value === currentRefinement)?.type
                      }&query_id=${product.__queryID}`}
                      image={product.image}
                      name={name.replace(/&nbsp;/gi, ' ')}
                      category={product.type}
                      subcategory={product.category[0]}
                      userHasAccess={
                        hasAllAccess ||
                        (!product.outsideSubscription && activeSubscriptionTypes.indexOf(product.type) > -1)
                      }
                      price={Number(regularPrice)}
                      discountedPrice={Number(price)}
                      promoActive={Boolean(promotion) && product.hasPromotions}
                      discount={promotion?.discount}
                      onClick={() => handleProductClick(product)}
                      aiPrompt={product.aiPrompt}
                      extraSettings={product.extraSettings}
                      designer={product.designer}
                      countdown={countDown}
                    />
                    {showFreeTrialCard ? (
                      <div className="mt-[10px] w-full">
                        <FreeTrialCard />
                      </div>
                    ) : null}
                  </Fragment>
                );
              })}
            </Masonry>
          )}
        </div>
        <div ref={loadMoreRef as React.LegacyRef<HTMLDivElement>} />
        <div>
          {canFetchNextPage ? (
            <div className="flex w-full justify-center">
              <Spinner size="2x" />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
