import * as Sentry from '@sentry/nextjs';
import { GetServerSideProps } from 'next';
import qs from 'qs';
import Head from 'next/head';
import { InstantSearchServerState, InstantSearchSSRProvider } from 'react-instantsearch';
import { SearchResponse } from '@algolia/client-search';
import { useRouter } from 'next/router';
import { CfMenuUserQuery, useCfMenuUserQuery } from 'codegen/generated/cf-menu';
import { TrackingData } from '@growthbook/growthbook';

import { SearchPage as SearchPageComponent } from 'components/pages/searchPage/SearchPage';
import { SearchPage as Searchv2PageComponent } from 'components/pages/searchv2Page/SearchPage';
import { NextPageWithLayout } from 'components/app/App.types';
import { Layout } from 'components/layout/Layout';
import { generateServerSidePropsHandler } from 'utilities/getServerSideProps';
import { searchClient, SearchPageProvider } from 'components/contexts/instantSearchProvider/SearchPageProvider';
import { searchClient as contentSearchClient } from 'components/contexts/instantSearchProvider/StockphotosSearchPageProvider';
import {
  productsAlgoliaIndex,
  contentAlgoliaIndex,
  contentAlgoliaIndexNewest,
  contentAlgoliaIndexOldest,
} from 'components/contexts/instantSearchProvider/InstantSearchProvider.utils';
import { getSearchState } from 'components/pages/searchPage/SearchPage.utils';
import { SearchProduct } from 'api/requests/search/search.types';
import {
  SEARCH_PAGE_COMMUNITY_CONTENT_ENABLED_TOGGLE_COOKIE_NAME,
  SEARCH_PAGE_AI_CONTENT_ENABLED_TOGGLE_COOKIE_NAME,
  SearchableFilters,
} from 'components/pages/searchPage/components/SearchSidebar/SearchSidebar';
import { AlgoliaContentHit } from 'components/elements/organisms/algoliaConnected/SearchResults/SearchResults.types';
import { ALLOWED_CONTENT_PRODUCTS_AUTHOR_IDS } from 'components/pages/searchPage/SearchPage.utils';
import { getFeatureFlags } from 'utilities/getFeatureFlags';
import { GrowthBookTracking } from 'components/app/analytics/growthBookTracking/growthBookTracking';
import { AMPLITUDE_DEVICE_ID_COOKIE_NAME } from 'components/app/analytics/cfTracking/CfTracking.utils';

const SearchPage: NextPageWithLayout<{
  initialQuery: string;
  initialAlgoliaData?: InstantSearchServerState;
  searchv2?: boolean;
  trackingData: TrackingData[];
}> = ({ initialQuery, initialAlgoliaData, searchv2, trackingData }) => {
  const router = useRouter();
  const path = router.asPath.split('?')[0];
  const canonicalPathname = path.split('ref/')[0];

  // eslint-disable-next-line no-console
  console.log('searchv2 flag', searchv2);

  return (
    <>
      <Head>
        <title>Creative Fabrica - Premium Crafting Fonts, Graphics &amp; More</title>
        <meta property="og:title" content="Creative Fabrica - Premium Crafting Fonts, Graphics &amp; More" />
        <meta
          name="description"
          content="On Creative Fabrica you will find the best font &amp; graphic deals at the best prices. Want more? Sign up for on of our subscription and get unlimited access."
        />
        <meta
          property="og:description"
          content="On Creative Fabrica you will find the best font &amp; graphic deals at the best prices. Want more? Sign up for on of our subscription and get unlimited access."
        />
        <link
          key="canonicalTag$canonical"
          rel="canonical"
          href={`${process.env.NEXT_PUBLIC_API_URL}${
            router?.locale !== 'en' ? `/${router?.locale}` : ''
          }${canonicalPathname}`}
        />
        <meta
          key="og:url$og:url"
          property="og:url"
          content={`${process.env.NEXT_PUBLIC_API_URL}${router?.locale !== 'en' ? `/${router?.locale}` : ''}${path}`}
        />
        <meta
          key="viewport"
          property="og:viewport"
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <meta
          property="og:image"
          content="https://www.creativefabrica.com/wp-content/uploads/2019/01/creative-fabrica-main.png"
        />
        <meta
          property="og:image:secure_url"
          content="https://www.creativefabrica.com/wp-content/uploads/2019/01/creative-fabrica-main.png"
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Head>
      <InstantSearchSSRProvider {...initialAlgoliaData}>
        <SearchPageProvider initialQuery={initialQuery}>
          {searchv2 ? <Searchv2PageComponent /> : <SearchPageComponent />}
        </SearchPageProvider>
      </InstantSearchSSRProvider>
      <GrowthBookTracking data={trackingData} />
    </>
  );
};

SearchPage.getLayout = page => <Layout>{page}</Layout>;

export const getServerSideProps: GetServerSideProps = async context => {
  const handler = await generateServerSidePropsHandler(context);

  const localeRedirect = await handler.getLocaleRedirector();

  if (localeRedirect.hasRedirect) {
    return { redirect: localeRedirect.redirect };
  }

  const deviceId = context.req.cookies[AMPLITUDE_DEVICE_ID_COOKIE_NAME];

  try {
    const initialQuery = qs.stringify(context.query, { arrayFormat: 'comma' });
    const isCommunityContentEnabled = Boolean(
      context.req.cookies[SEARCH_PAGE_COMMUNITY_CONTENT_ENABLED_TOGGLE_COOKIE_NAME] === 'true',
    );

    const isAiContentEnabled = Boolean(
      context.req.cookies[SEARCH_PAGE_AI_CONTENT_ENABLED_TOGGLE_COOKIE_NAME] === 'true',
    );
    const aiContentFilteredBy = context.query?.aiContentType ? (context.query?.aiContentType as string) : null;

    const isClassSearch = context.query.type === 'Classes';

    const searchState = getSearchState({
      url: initialQuery,
      isCommunityContentEnabled,
      isClassSearch,
      isAiContentEnabled,
    });
    const uiState = searchState.uiState[productsAlgoliaIndex];
    const indexName = uiState.sortBy || productsAlgoliaIndex;
    const facets = Object.keys(uiState.refinementList || {}).map(key => key);
    const facetFilters: string[][] = Object.keys(uiState.refinementList || {})
      .map(key => {
        if (uiState.refinementList) {
          return uiState.refinementList[key].map(value => `${key}:${value}`);
        }

        return [];
      })
      .filter(item => item && item?.length > 0);

    const filters = uiState.configure?.filters || '';

    const requests = !aiContentFilteredBy
      ? [
          {
            indexName: indexName,
            params: {
              clickAnalytics: true,
              hitsPerPage: uiState.configure?.hitsPerPage,
              maxValuesPerFacet: 999,
              filters: filters,
              optionalFilters: uiState.configure?.optionalFilters || '',
              page: Number(uiState.page) - 1,
              query: uiState.query,
              facets: facets,
              facetFilters: facetFilters,
            },
          },
        ]
      : [];

    const contentFilter = aiContentFilteredBy
      ? `authorId:${aiContentFilteredBy}`
      : ALLOWED_CONTENT_PRODUCTS_AUTHOR_IDS.map(id => `authorId:${id}`).join(' OR ');

    const contentRequests =
      isAiContentEnabled && !isClassSearch
        ? [
            {
              indexName: contentAlgoliaIndex,
              params: {
                clickAnalytics: true,
                hitsPerPage: uiState.configure?.hitsPerPage,
                maxValuesPerFacet: 999,
                filters: contentFilter,
                optionalFilters: '',
                page: Number(uiState.page) - 1,
                query: uiState.query,
                facets: ['authorId'],
                facetFilters: [],
              },
            },
          ]
        : [];

    const protocol = context.req.headers.referer?.split('://')[0] || 'https';
    const serverUrl = `${protocol}://${context.req.headers.host}${context.req.url}`;

    const index = searchClient.initIndex(indexName);
    const contentIndex = indexName.includes('newest')
      ? contentAlgoliaIndexNewest
      : indexName.includes('oldest')
      ? contentAlgoliaIndexOldest
      : contentAlgoliaIndex;

    const content = contentSearchClient.initIndex(contentIndex);

    let indexData = await index.search<SearchProduct>(uiState.query || '', {
      ...requests[0].params,
      clickAnalytics: true,
      headers: {
        accept: 'application/json',
        'User-Agent': context.req.headers['user-agent'] || '',
        Referer: serverUrl,
      },
    });
    const refined = uiState.refinementList || {};

    const shouldFetchContentData =
      isAiContentEnabled && !isClassSearch
        ? Object.keys(refined).find(key => refined[key].length > 0) === undefined
        : false;

    let contentData: SearchResponse<AlgoliaContentHit> | null = null;

    if (shouldFetchContentData) {
      contentData = await content.search<AlgoliaContentHit>(uiState.query || '', {
        ...contentRequests[0].params,
        clickAnalytics: true,
        headers: {
          accept: 'application/json',
          'User-Agent': context.req.headers['user-agent'] || '',
          Referer: serverUrl,
        },
      });

      const queryId = contentData.queryID;

      handler.queryClient.setQueryData(
        ['algoliaContentFetch.infinite', uiState.query, aiContentFilteredBy, contentIndex],
        {
          pages: [{ ...contentData, hits: contentData.hits.map(hit => ({ ...hit, __queryID: queryId })) }],
          pageParams: [Number(uiState.page) - 1],
        },
      );
    }

    let cfPremiumFacetValues: string[] = [];

    if (context.query.filter === 'brands' && indexData?.facets && indexData?.facets[SearchableFilters.PREMIUM]) {
      cfPremiumFacetValues = Object.keys(indexData?.facets[SearchableFilters.PREMIUM]);
      facetFilters.push(cfPremiumFacetValues.map(value => `${SearchableFilters.PREMIUM}:${value}`));

      if (cfPremiumFacetValues.length > 0) {
        indexData = await index.search<SearchProduct>(uiState.query || '', {
          ...requests[0].params,
          facetFilters: facetFilters,
          clickAnalytics: true,
          headers: {
            accept: 'application/json',
            'User-Agent': context.req.headers['user-agent'] || '',
            Referer: serverUrl,
          },
        });
      }
    }

    const { getProps } = await handler.fetch();

    const menu = handler.queryClient.getQueryData<CfMenuUserQuery>(useCfMenuUserQuery.getKey());
    const {
      flags: { searchv2 },
      trackingData,
    } = await getFeatureFlags(menu?.me?.user?.id, deviceId);

    return {
      props: getProps({
        initialQuery: initialQuery,
        contentData: contentData,
        initialAlgoliaData: {
          initialResults: {
            [productsAlgoliaIndex]: {
              state: {
                facets: facets,
                disjunctiveFacets: facets,
                disjunctiveFacetsRefinements: {
                  ...uiState.refinementList,
                  [SearchableFilters.PREMIUM]: cfPremiumFacetValues,
                },
                hierarchicalFacets: [],
                facetsRefinements: {},
                facetsExcludes: {},
                numericRefinements: {},
                tagRefinements: [],
                hierarchicalFacetsRefinements: {},
                index: indexName,
                clickAnalytics: true,
                maxValuesPerFacet: 20,
                query: uiState.query,
                highlightPreTag: '__ais-highlight__',
                highlightPostTag: '__/ais-highlight__',
              },
              results: [indexData],
            },
          },
        },
        searchv2,
        trackingData,
      }),
    };
  } catch (er) {
    Sentry.captureException(new Error(er as string));

    const { getProps } = await handler.fetch();

    const menu = handler.queryClient.getQueryData<CfMenuUserQuery>(useCfMenuUserQuery.getKey());
    const {
      flags: { searchv2 },
      trackingData,
    } = await getFeatureFlags(menu?.me?.user?.id, deviceId);

    return {
      props: getProps({
        searchv2,
        trackingData,
      }),
    };
  }
};

export default SearchPage;
